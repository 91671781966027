<template>
    <div class="bg-white p-4 lg:p-6 min-h-full shadow-xl rounded-xl ">

            
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>